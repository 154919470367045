import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> &
  { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> &
  { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {};

export interface IntrospectionResultData {
  __schema: {
    types: {
      kind: string;
      name: string;
      possibleTypes: {
        name: string;
      }[];
    }[];
  };
}
const result: IntrospectionResultData = {
  __schema: {
    types: [],
  },
};
export default result;

/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** The `JSONObject` scalar type represents JSON objects as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSONObject: any;
  /** A date-time string at UTC, such as 2019-12-03T09:54:33Z, compliant with the date-time format. */
  DateTime: any;
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: any;
};

export type Address = {
  __typename?: 'Address';
  recipient: Scalars['String'];
  line1: Scalars['String'];
  line2?: Maybe<Scalars['String']>;
  city: Scalars['String'];
  state: Scalars['String'];
  postalCode: Scalars['String'];
  /** ISO 3166-1 Alpha 2 Country Code (https://en.wikipedia.org/wiki/ISO_3166-1#Officially_assigned_code_elements) */
  country: Scalars['String'];
  countryAsAlpha3: Scalars['String'];
};

export type Adjustment = {
  __typename?: 'Adjustment';
  id: Scalars['String'];
  type: AdjustmentType;
  amount: Scalars['Float'];
  meta?: Maybe<Scalars['JSONObject']>;
};

export enum AdjustmentType {
  Discount = 'DISCOUNT',
  Shipping = 'SHIPPING',
  Tax = 'TAX',
  Addon = 'ADDON',
}

export type Destination = {
  __typename?: 'Destination';
  url: Scalars['String'];
  method: DestinationMethod;
  headers?: Maybe<Scalars['JSONObject']>;
};

export enum DestinationMethod {
  Post = 'POST',
  Get = 'GET',
}

export type Hook = {
  __typename?: 'Hook';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  event: HookEvent;
  destinations: Array<Destination>;
};

export enum HookEvent {
  CartCreated = 'CART_CREATED',
  CartUpdated = 'CART_UPDATED',
  CartPayment = 'CART_PAYMENT',
  ItemsAdded = 'ITEMS_ADDED',
  ItemsRemoved = 'ITEMS_REMOVED',
  ItemUpdated = 'ITEM_UPDATED',
}

export type PrinterJobFile = {
  __typename?: 'PrinterJobFile';
  id: Scalars['String'];
  url: Scalars['String'];
  fileName: Scalars['String'];
  directory: Scalars['String'];
  meta: Scalars['JSONObject'];
};

export type Product = {
  __typename?: 'Product';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  name: Scalars['String'];
  sku: Scalars['String'];
  type: ProductType;
  category: Scalars['String'];
  unitPrice: Scalars['Float'];
  commodityCode?: Maybe<Scalars['String']>;
  upc?: Maybe<Scalars['String']>;
  requiresApproval?: Maybe<Scalars['Boolean']>;
  meta?: Maybe<Scalars['JSONObject']>;
  children?: Maybe<Array<Product>>;
};

export enum ProductType {
  Physical = 'PHYSICAL',
  Digital = 'DIGITAL',
  Other = 'OTHER',
}

export type VoucherApplicableTo = {
  __typename?: 'VoucherApplicableTo';
  method: VoucherApplicableToMethod;
  match: Scalars['JSON'];
};

export enum VoucherApplicableToMethod {
  All = 'ALL',
  First = 'FIRST',
}

export type VoucherRule = {
  __typename?: 'VoucherRule';
  discount?: Maybe<Scalars['Float']>;
  match: Scalars['JSON'];
};

export type Voucher = {
  __typename?: 'Voucher';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  applicableTo?: Maybe<VoucherApplicableTo>;
  rules: Array<VoucherRule>;
  code: Scalars['String'];
  type: VoucherDiscountType;
  discount: Scalars['Float'];
  startsAt: Scalars['DateTime'];
  expiresAt?: Maybe<Scalars['DateTime']>;
  useCount: Scalars['Float'];
  maxUses?: Maybe<Scalars['Float']>;
  isUsable: Scalars['Boolean'];
};

export enum VoucherDiscountType {
  Amount = 'AMOUNT',
  Percent = 'PERCENT',
}

export type Fulfillment = {
  __typename?: 'Fulfillment';
  id: Scalars['String'];
  items: Array<Scalars['String']>;
  source: Scalars['String'];
  status: FulfillmentStatus;
  meta?: Maybe<Scalars['JSONObject']>;
  notes?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  internalMeta: Scalars['JSONObject'];
};

export enum FulfillmentStatus {
  Pending = 'PENDING',
  Fulfilled = 'FULFILLED',
  Cancelled = 'CANCELLED',
}

export type Event = {
  __typename?: 'Event';
  type: Scalars['String'];
  message: Scalars['String'];
  createdAt: Scalars['DateTime'];
};

export type Transaction = {
  __typename?: 'Transaction';
  id: Scalars['String'];
  source: Scalars['String'];
  sourceId?: Maybe<Scalars['String']>;
  sourceStatus?: Maybe<Scalars['String']>;
  type: TransactionType;
  amount: Scalars['Float'];
  createdAt: Scalars['DateTime'];
};

export enum TransactionType {
  AndroidPay = 'ANDROID_PAY',
  ApplePay = 'APPLE_PAY',
  CreditCard = 'CREDIT_CARD',
  Masterpass = 'MASTERPASS',
  Paypal = 'PAYPAL',
  SamsungPay = 'SAMSUNG_PAY',
  UsBankAccount = 'US_BANK_ACCOUNT',
  VenmoAccount = 'VENMO_ACCOUNT',
  VisaCheckout = 'VISA_CHECKOUT',
  Unknown = 'UNKNOWN',
}

export type Item = {
  __typename?: 'Item';
  id: Scalars['String'];
  name: Scalars['String'];
  sku: Scalars['String'];
  category: Scalars['String'];
  unitPrice: Scalars['Float'];
  commodityCode?: Maybe<Scalars['String']>;
  upc?: Maybe<Scalars['String']>;
  quantity: Scalars['Float'];
  total: Scalars['Float'];
  meta?: Maybe<Scalars['JSONObject']>;
  metaUpdatedAt?: Maybe<Scalars['DateTime']>;
  addedAt: Scalars['DateTime'];
  isPaid: Scalars['Boolean'];
  isRefunded: Scalars['Boolean'];
  payment?: Maybe<Transaction>;
  refund?: Maybe<Transaction>;
  product?: Maybe<Product>;
};

export type Shipment = {
  __typename?: 'Shipment';
  id: Scalars['String'];
  fulfillment: Scalars['String'];
  status: ShipmentStatus;
  source: Scalars['String'];
  sourceId: Scalars['String'];
  carrier: Scalars['String'];
  trackingNumber: Scalars['String'];
  notes?: Maybe<Scalars['String']>;
  meta?: Maybe<Scalars['JSONObject']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export enum ShipmentStatus {
  Pending = 'PENDING',
  InfoReceived = 'INFO_RECEIVED',
  InTransit = 'IN_TRANSIT',
  OutForDelivery = 'OUT_FOR_DELIVERY',
  AttemptFail = 'ATTEMPT_FAIL',
  Delivered = 'DELIVERED',
  Exception = 'EXCEPTION',
  Expired = 'EXPIRED',
  Cancelled = 'CANCELLED',
}

export type Cart = {
  __typename?: 'Cart';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  store?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  uuid?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  fullName?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  billingAddress?: Maybe<Address>;
  shippingAddress?: Maybe<Address>;
  status: CartStatus;
  items: Array<Item>;
  paid: Scalars['Float'];
  events: Array<Event>;
  payments: Array<Transaction>;
  refunds: Array<Transaction>;
  fulfillments: Array<Fulfillment>;
  shipments: Array<Shipment>;
  notes?: Maybe<Scalars['String']>;
  paidAt?: Maybe<Scalars['DateTime']>;
  approvedAt?: Maybe<Scalars['DateTime']>;
  approvedBy?: Maybe<ApprovedBy>;
  /** Total of items including adjustments */
  total: Scalars['Float'];
  adjustmentsTotal: Scalars['Float'];
  itemsTotal: Scalars['Float'];
  meta?: Maybe<Scalars['JSONObject']>;
  isPaid: Scalars['Boolean'];
  isApproved: Scalars['Boolean'];
  isEditable: Scalars['Boolean'];
  adjustments: Array<Adjustment>;
  history: Array<CartHistoryItem>;
  customer?: Maybe<Customer>;
  voucher?: Maybe<Adjustment>;
};

export type CartAdjustmentsArgs = {
  type?: Maybe<AdjustmentType>;
};

export enum CartStatus {
  Draft = 'DRAFT',
  Hold = 'HOLD',
  CustomerHold = 'CUSTOMER_HOLD',
  Ready = 'READY',
  PartiallyFulfilled = 'PARTIALLY_FULFILLED',
  Fulfilled = 'FULFILLED',
  Shipping = 'SHIPPING',
  PendingCancel = 'PENDING_CANCEL',
  Cancelled = 'CANCELLED',
  Completed = 'COMPLETED',
}

export enum ApprovedBy {
  System = 'SYSTEM',
  Admin = 'ADMIN',
  Customer = 'CUSTOMER',
}

export type CartHistoryItem = {
  __typename?: 'CartHistoryItem';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  store?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  uuid?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  fullName?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  billingAddress?: Maybe<Address>;
  shippingAddress?: Maybe<Address>;
  status: CartStatus;
  items: Array<Item>;
  paid: Scalars['Float'];
  events: Array<Event>;
  payments: Array<Transaction>;
  refunds: Array<Transaction>;
  fulfillments: Array<Fulfillment>;
  shipments: Array<Shipment>;
  notes?: Maybe<Scalars['String']>;
  paidAt?: Maybe<Scalars['DateTime']>;
  approvedAt?: Maybe<Scalars['DateTime']>;
  approvedBy?: Maybe<ApprovedBy>;
  /** Total of items including adjustments */
  total: Scalars['Float'];
  adjustmentsTotal: Scalars['Float'];
  itemsTotal: Scalars['Float'];
  meta?: Maybe<Scalars['JSONObject']>;
  isPaid: Scalars['Boolean'];
  isApproved: Scalars['Boolean'];
  isEditable: Scalars['Boolean'];
  adjustments: Array<Adjustment>;
  history: Array<CartHistoryItem>;
  customer?: Maybe<Customer>;
  voucher?: Maybe<Adjustment>;
};

export type CartHistoryItemAdjustmentsArgs = {
  type?: Maybe<AdjustmentType>;
};

export type Collection = {
  __typename?: 'Collection';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  slug: Scalars['String'];
  name: Scalars['String'];
  description: Scalars['String'];
  products?: Maybe<Array<Product>>;
};

export type Customer = {
  __typename?: 'Customer';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  email: Scalars['String'];
  meta?: Maybe<Scalars['JSONObject']>;
};

export type TimelineSnapshot = {
  __typename?: 'TimelineSnapshot';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  store?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  uuid?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  fullName?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  billingAddress?: Maybe<Address>;
  shippingAddress?: Maybe<Address>;
  status: CartStatus;
  items: Array<Item>;
  paid: Scalars['Float'];
  events: Array<Event>;
  payments: Array<Transaction>;
  refunds: Array<Transaction>;
  fulfillments: Array<Fulfillment>;
  shipments: Array<Shipment>;
  notes?: Maybe<Scalars['String']>;
  paidAt?: Maybe<Scalars['DateTime']>;
  approvedAt?: Maybe<Scalars['DateTime']>;
  approvedBy?: Maybe<ApprovedBy>;
  /** Total of items including adjustments */
  total: Scalars['Float'];
  adjustmentsTotal: Scalars['Float'];
  itemsTotal: Scalars['Float'];
  meta?: Maybe<Scalars['JSONObject']>;
  isPaid: Scalars['Boolean'];
  isApproved: Scalars['Boolean'];
  isEditable: Scalars['Boolean'];
  adjustments: Array<Adjustment>;
  history: Array<CartHistoryItem>;
  customer?: Maybe<Customer>;
  voucher?: Maybe<Adjustment>;
};

export type TimelineSnapshotAdjustmentsArgs = {
  type?: Maybe<AdjustmentType>;
};

export type Timeline = {
  __typename?: 'Timeline';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  type: Scalars['String'];
  text: Scalars['String'];
  snapshot: TimelineSnapshot;
  meta?: Maybe<Scalars['JSONObject']>;
  user?: Maybe<User>;
};

export type User = {
  __typename?: 'User';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  name: Scalars['String'];
  email: Scalars['String'];
  roles: Array<Scalars['String']>;
  isDisabled: Scalars['Boolean'];
};

export type Store = {
  __typename?: 'Store';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  code: Scalars['String'];
  name: Scalars['String'];
  url: Scalars['String'];
  cartURL: Scalars['String'];
  orderURL: Scalars['String'];
  orderEmail?: Maybe<Scalars['String']>;
  supportEmail?: Maybe<Scalars['String']>;
  meta: Scalars['JSONObject'];
};

export type UserAndTokenType = {
  __typename?: 'UserAndTokenType';
  user: User;
  token: Scalars['String'];
};

export type OrdersQueryResultsPagination = {
  __typename?: 'OrdersQueryResultsPagination';
  next?: Maybe<Scalars['String']>;
};

export type OrdersQueryResults = {
  __typename?: 'OrdersQueryResults';
  pagination: OrdersQueryResultsPagination;
  results: Array<Cart>;
};

export type CreateBraintreeClientTokenResponseType = {
  __typename?: 'CreateBraintreeClientTokenResponseType';
  clientToken: Scalars['String'];
};

export type Query = {
  __typename?: 'Query';
  users: Array<User>;
  me: User;
  cart?: Maybe<Cart>;
  cartFromFulfillment?: Maybe<Cart>;
  timelines: Array<Timeline>;
  carts: Array<Cart>;
  orders: OrdersQueryResults;
  hooks: Array<Hook>;
  braintreeCustomer?: Maybe<Scalars['JSON']>;
  ping: Scalars['JSONObject'];
  httpException: Scalars['JSONObject'];
  errorException: Scalars['JSONObject'];
  authError: Scalars['JSONObject'];
  internalError: Scalars['JSONObject'];
  inputValidation: Scalars['JSONObject'];
  collections: Array<Collection>;
  collection: Collection;
  product?: Maybe<Product>;
  products: Array<Product>;
  stores: Array<Store>;
  vouchers: Array<Voucher>;
  voucher: Voucher;
  taxRates?: Maybe<Scalars['JSON']>;
};

export type QueryCartArgs = {
  id: Scalars['String'];
};

export type QueryCartFromFulfillmentArgs = {
  id: Scalars['String'];
};

export type QueryTimelinesArgs = {
  cart: Scalars['String'];
};

export type QueryCartsArgs = {
  from?: Maybe<Scalars['DateTime']>;
  to?: Maybe<Scalars['DateTime']>;
  search?: Maybe<Scalars['String']>;
  status?: Maybe<Array<CartStatus>>;
  limit?: Maybe<Scalars['Float']>;
  skip?: Maybe<Scalars['Float']>;
  sort?: Maybe<Scalars['JSON']>;
};

export type QueryOrdersArgs = {
  query?: Maybe<OrdersQuery>;
  paidAt?: Maybe<DateRangeInput>;
  status?: Maybe<Array<CartStatus>>;
  filter?: Maybe<Scalars['JSONObject']>;
  next?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Int']>;
  reverse?: Maybe<Scalars['Boolean']>;
};

export type QueryBraintreeCustomerArgs = {
  input: FindBraintreeCustomerInput;
};

export type QueryInputValidationArgs = {
  input: SomeInput;
};

export type QueryCollectionArgs = {
  slug?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
};

export type QueryProductArgs = {
  input: ProductQueryInput;
};

export type QueryVoucherArgs = {
  code: Scalars['String'];
};

export type QueryTaxRatesArgs = {
  force?: Maybe<Scalars['Boolean']>;
};

export enum OrdersQuery {
  All = 'ALL',
  ApprovedOnly = 'APPROVED_ONLY',
  UnapprovedOnly = 'UNAPPROVED_ONLY',
}

export type DateRangeInput = {
  greaterThanOrEqualTo?: Maybe<Scalars['DateTime']>;
  lessThanOrEqualTo?: Maybe<Scalars['DateTime']>;
};

export type FindBraintreeCustomerInput = {
  store: Scalars['String'];
  email: Scalars['String'];
};

export type SomeInput = {
  foo: Scalars['JSONObject'];
  bar?: Maybe<Scalars['JSONObject']>;
};

export type ProductQueryInput = {
  id?: Maybe<Scalars['String']>;
  sku?: Maybe<Scalars['String']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  seedUser: User;
  registerUser: User;
  updateUser: User;
  auth: UserAndTokenType;
  createCart?: Maybe<Cart>;
  updateCart?: Maybe<Cart>;
  updateCartStatus: Cart;
  approveCart: Cart;
  addItemsToCart?: Maybe<Cart>;
  updateCartItem?: Maybe<Cart>;
  removeItemsFromCart?: Maybe<Cart>;
  addAdjustmentToCart?: Maybe<Cart>;
  updateAdjustmentForCart?: Maybe<Cart>;
  removeAdjustmentFromCart?: Maybe<Cart>;
  sendMagicLinkForCart: Scalars['Boolean'];
  exchangeMagicLinkTokenForCart: Scalars['String'];
  updateTimeline?: Maybe<Timeline>;
  createCustomer?: Maybe<Customer>;
  addWebhook: Hook;
  updateWebhook: Hook;
  removeWebhook: Hook;
  testWebhook: Scalars['Boolean'];
  addFulfillmentToCart: Cart;
  updateFulfillmentForCart: Cart;
  temp__fulfillPrintful: Cart;
  temp__fulfillShipStation: Cart;
  createBraintreeClientToken?: Maybe<CreateBraintreeClientTokenResponseType>;
  chargeBraintree?: Maybe<Cart>;
  testPayment?: Maybe<Cart>;
  addPayment?: Maybe<Cart>;
  createCollection: Collection;
  updateCollection: Collection;
  createProduct: Product;
  updateProduct: Product;
  addShipmentToCart: Cart;
  updateShipmentForCart: Cart;
  createStore?: Maybe<Store>;
  updateStore?: Maybe<Store>;
  createVoucher: Voucher;
  updateVoucher: Voucher;
  applyVoucher?: Maybe<Cart>;
  removeVoucher?: Maybe<Cart>;
  validateAddress: Address;
};

export type MutationSeedUserArgs = {
  input: RegisterInput;
};

export type MutationRegisterUserArgs = {
  input: RegisterInput;
};

export type MutationUpdateUserArgs = {
  input: UpdateUserInput;
};

export type MutationAuthArgs = {
  password: Scalars['String'];
  email: Scalars['String'];
};

export type MutationCreateCartArgs = {
  input?: Maybe<CreateCartInput>;
};

export type MutationUpdateCartArgs = {
  input: UpdateCartInput;
};

export type MutationUpdateCartStatusArgs = {
  input: UpdateCartStatusInput;
};

export type MutationApproveCartArgs = {
  input: ApproveCartInput;
};

export type MutationAddItemsToCartArgs = {
  input: AddItemsToCartInput;
};

export type MutationUpdateCartItemArgs = {
  input: UpdateCartItemInput;
};

export type MutationRemoveItemsFromCartArgs = {
  input: RemoveItemsFromCartInput;
};

export type MutationAddAdjustmentToCartArgs = {
  input: AddAdjustmentToCartInput;
};

export type MutationUpdateAdjustmentForCartArgs = {
  input: UpdateAdjustmentForCartInput;
};

export type MutationRemoveAdjustmentFromCartArgs = {
  input: RemoveAdjustmentFromCartInput;
};

export type MutationSendMagicLinkForCartArgs = {
  input: SendMagicLinkForCartInput;
};

export type MutationExchangeMagicLinkTokenForCartArgs = {
  input: ExchangeMagicLinkTokenForCartInput;
};

export type MutationUpdateTimelineArgs = {
  input: UpdateTimelineInput;
};

export type MutationCreateCustomerArgs = {
  input: CreateCustomerInput;
};

export type MutationAddWebhookArgs = {
  input: AddWebhookInput;
};

export type MutationUpdateWebhookArgs = {
  input: UpdateWebhookInput;
};

export type MutationRemoveWebhookArgs = {
  input: RemoveWebhookInput;
};

export type MutationTestWebhookArgs = {
  input: DestinationInput;
};

export type MutationAddFulfillmentToCartArgs = {
  input: AddFulfillmentToCartInput;
};

export type MutationUpdateFulfillmentForCartArgs = {
  input: UpdateFulfillmentForCartInput;
};

export type MutationTemp__FulfillPrintfulArgs = {
  input: PrintfulFulfillmentInput;
};

export type MutationTemp__FulfillShipStationArgs = {
  input: CreateShipStationOrderInput;
};

export type MutationCreateBraintreeClientTokenArgs = {
  input: CreateBraintreeClientTokenInput;
};

export type MutationChargeBraintreeArgs = {
  input: ChargeBraintreeInput;
};

export type MutationTestPaymentArgs = {
  id: Scalars['String'];
};

export type MutationAddPaymentArgs = {
  input: AddPaymentInput;
};

export type MutationCreateCollectionArgs = {
  input: CollectionInput;
};

export type MutationUpdateCollectionArgs = {
  input: UpdateCollectionInput;
};

export type MutationCreateProductArgs = {
  input: CreateProductInput;
};

export type MutationUpdateProductArgs = {
  input: UpdateProductInput;
};

export type MutationAddShipmentToCartArgs = {
  input: AddShipmentToCartInput;
};

export type MutationUpdateShipmentForCartArgs = {
  input: UpdateShipmentForCartInput;
};

export type MutationCreateStoreArgs = {
  input?: Maybe<CreateStoreInput>;
};

export type MutationUpdateStoreArgs = {
  input?: Maybe<UpdateStoreInput>;
};

export type MutationCreateVoucherArgs = {
  input: CreateVoucherInput;
};

export type MutationUpdateVoucherArgs = {
  input: UpdateVoucherInput;
};

export type MutationApplyVoucherArgs = {
  input: ApplyOrRemoveVoucherInput;
};

export type MutationRemoveVoucherArgs = {
  input: ApplyOrRemoveVoucherInput;
};

export type MutationValidateAddressArgs = {
  input: AddressInput;
};

export type RegisterInput = {
  name: Scalars['String'];
  email: Scalars['String'];
  password: Scalars['String'];
  roles: Array<Scalars['String']>;
  isDisabled?: Maybe<Scalars['Boolean']>;
};

export type UpdateUserInput = {
  id: Scalars['String'];
  data: UpdateUserData;
};

export type UpdateUserData = {
  name?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  roles?: Maybe<Array<Scalars['String']>>;
  isDisabled?: Maybe<Scalars['Boolean']>;
};

export type CreateCartInput = {
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  billingAddress?: Maybe<AddressInput>;
  shippingAddress?: Maybe<AddressInput>;
  items?: Maybe<Array<CartItemInput>>;
  meta?: Maybe<Scalars['JSONObject']>;
  store: Scalars['String'];
};

export type AddressInput = {
  recipient: Scalars['String'];
  line1: Scalars['String'];
  line2?: Maybe<Scalars['String']>;
  city: Scalars['String'];
  state: Scalars['String'];
  postalCode: Scalars['String'];
  /** ISO 3166-1 Alpha 2 Country Code (https://en.wikipedia.org/wiki/ISO_3166-1#Officially_assigned_code_elements) */
  country: Scalars['String'];
};

export type CartItemInput = {
  quantity: Scalars['Float'];
  sku: Scalars['String'];
  meta?: Maybe<Scalars['JSONObject']>;
};

export type UpdateCartInput = {
  id: Scalars['String'];
  data: UpdateCartPatchInput;
};

export type UpdateCartPatchInput = {
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  billingAddress?: Maybe<AddressInput>;
  shippingAddress?: Maybe<AddressInput>;
  items?: Maybe<Array<CartItemInput>>;
  meta?: Maybe<Scalars['JSONObject']>;
};

export type UpdateCartStatusInput = {
  id: Scalars['String'];
  status: CartStatus;
  timeline?: Maybe<TimelineBasicInput>;
};

export type TimelineBasicInput = {
  text?: Maybe<Scalars['String']>;
  meta?: Maybe<Scalars['JSONObject']>;
};

export type ApproveCartInput = {
  id: Scalars['String'];
};

export type AddItemsToCartInput = {
  id: Scalars['String'];
  items: Array<CartItemInput>;
};

export type UpdateCartItemInput = {
  id: Scalars['String'];
  item: Scalars['String'];
  data: UpdateCartItemDataInput;
};

export type UpdateCartItemDataInput = {
  quantity?: Maybe<Scalars['Float']>;
  sku?: Maybe<Scalars['String']>;
  meta?: Maybe<Scalars['JSONObject']>;
};

export type RemoveItemsFromCartInput = {
  id: Scalars['String'];
  items: Array<Scalars['String']>;
};

export type AddAdjustmentToCartInput = {
  id: Scalars['String'];
  data: AdjustmentInput;
};

export type AdjustmentInput = {
  type: AdjustmentType;
  amount?: Maybe<Scalars['Float']>;
  meta?: Maybe<Scalars['JSON']>;
};

export type UpdateAdjustmentForCartInput = {
  id: Scalars['String'];
  adjustment: Scalars['String'];
  data: AdjustmentInput;
};

export type RemoveAdjustmentFromCartInput = {
  id: Scalars['String'];
  adjustment: Scalars['String'];
};

export type SendMagicLinkForCartInput = {
  id: Scalars['String'];
};

export type ExchangeMagicLinkTokenForCartInput = {
  id: Scalars['String'];
  token: Scalars['String'];
};

export type UpdateTimelineInput = {
  cart: Scalars['String'];
  timeline?: Maybe<Scalars['String']>;
  data: TimelineInput;
};

export type TimelineInput = {
  type?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
  meta?: Maybe<Scalars['JSONObject']>;
};

export type CreateCustomerInput = {
  email: Scalars['String'];
  meta?: Maybe<Scalars['JSONObject']>;
};

export type AddWebhookInput = {
  event: HookEvent;
  data: DestinationInput;
};

export type DestinationInput = {
  url: Scalars['String'];
  method: DestinationMethod;
  headers?: Maybe<Scalars['JSONObject']>;
};

export type UpdateWebhookInput = {
  event: HookEvent;
  url: Scalars['String'];
  data: UpdateDestinationInput;
};

export type UpdateDestinationInput = {
  url: Scalars['String'];
  method: DestinationMethod;
  headers?: Maybe<Scalars['JSONObject']>;
};

export type RemoveWebhookInput = {
  event: HookEvent;
  url: Scalars['String'];
};

export type AddFulfillmentToCartInput = {
  id: Scalars['String'];
  data: FulfillmentInput;
};

export type FulfillmentInput = {
  source: Scalars['String'];
  status: FulfillmentStatus;
  meta?: Maybe<Scalars['JSONObject']>;
  items: Array<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  internalMeta?: Maybe<Scalars['JSONObject']>;
};

export type UpdateFulfillmentForCartInput = {
  id: Scalars['String'];
  fulfillment: Scalars['String'];
  data: UpdateFulfillmentInput;
};

export type UpdateFulfillmentInput = {
  source?: Maybe<Scalars['String']>;
  status?: Maybe<FulfillmentStatus>;
  meta?: Maybe<Scalars['JSONObject']>;
  items?: Maybe<Array<Scalars['String']>>;
  notes?: Maybe<Scalars['String']>;
  internalMeta?: Maybe<Scalars['JSONObject']>;
};

export type PrintfulFulfillmentInput = {
  id: Scalars['String'];
  items: Array<PrintfulFulfillmentItemInput>;
};

export type PrintfulFulfillmentItemInput = {
  id: Scalars['String'];
  image: Scalars['String'];
};

export type CreateShipStationOrderInput = {
  id: Scalars['String'];
  items: Array<CreateShipStationOrderItemInput>;
  tagIds: Array<Scalars['Float']>;
  advancedOptions?: Maybe<Scalars['JSONObject']>;
};

export type CreateShipStationOrderItemInput = {
  id: Scalars['String'];
  meta: CreateShipStationOrderItemMetaInput;
};

export type CreateShipStationOrderItemMetaInput = {
  fulfillmentSku: Scalars['String'];
  options: Array<CreateShipStationOrderItemOptionInput>;
};

export type CreateShipStationOrderItemOptionInput = {
  name: Scalars['String'];
  value: Scalars['String'];
};

export type CreateBraintreeClientTokenInput = {
  id: Scalars['String'];
  merchantAccountId?: Maybe<Scalars['String']>;
};

export type ChargeBraintreeInput = {
  id: Scalars['String'];
  paymentMethodId: Scalars['String'];
};

export type AddPaymentInput = {
  /** Cart ID */
  id: Scalars['String'];
  source: Scalars['String'];
  sourceId?: Maybe<Scalars['String']>;
  type?: Maybe<TransactionType>;
  amount?: Maybe<Scalars['Float']>;
  meta?: Maybe<Scalars['JSONObject']>;
};

export type CollectionInput = {
  slug: Scalars['String'];
  name: Scalars['String'];
  productIds: Array<Scalars['String']>;
};

export type UpdateCollectionInput = {
  id: Scalars['String'];
  data: CollectionInput;
};

export type CreateProductInput = {
  name: Scalars['String'];
  sku: Scalars['String'];
  type: ProductType;
  category: Scalars['String'];
  unitPrice: Scalars['Float'];
  commodityCode?: Maybe<Scalars['String']>;
  upc?: Maybe<Scalars['String']>;
  children?: Maybe<Array<Scalars['String']>>;
  requiresApproval: Scalars['Boolean'];
  meta?: Maybe<Scalars['JSONObject']>;
};

export type UpdateProductInput = {
  id: Scalars['String'];
  data: UpdateProductDataInput;
};

export type UpdateProductDataInput = {
  name?: Maybe<Scalars['String']>;
  sku?: Maybe<Scalars['String']>;
  type?: Maybe<ProductType>;
  category?: Maybe<Scalars['String']>;
  unitPrice?: Maybe<Scalars['Float']>;
  commodityCode?: Maybe<Scalars['String']>;
  upc?: Maybe<Scalars['String']>;
  children?: Maybe<Array<Scalars['String']>>;
  requiresApproval?: Maybe<Scalars['Boolean']>;
  meta?: Maybe<Scalars['JSONObject']>;
};

export type AddShipmentToCartInput = {
  id: Scalars['String'];
  fulfillment: Scalars['String'];
  data: ShipmentInput;
};

export type ShipmentInput = {
  source: Scalars['String'];
  sourceId: Scalars['String'];
  status: ShipmentStatus;
  carrier: Scalars['String'];
  trackingNumber: Scalars['String'];
  notes: Scalars['String'];
  meta?: Maybe<Scalars['JSONObject']>;
};

export type UpdateShipmentForCartInput = {
  id: Scalars['String'];
  fulfillment: Scalars['String'];
  data: UpdateShipmentInput;
};

export type UpdateShipmentInput = {
  source?: Maybe<Scalars['String']>;
  sourceId?: Maybe<Scalars['String']>;
  status?: Maybe<ShipmentStatus>;
  carrier?: Maybe<Scalars['String']>;
  trackingNumber?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  meta?: Maybe<Scalars['JSONObject']>;
};

export type CreateStoreInput = {
  code: Scalars['String'];
  name: Scalars['String'];
  url: Scalars['String'];
  cartURL: Scalars['String'];
  orderURL: Scalars['String'];
  orderEmail?: Maybe<Scalars['String']>;
  supportEmail?: Maybe<Scalars['String']>;
  meta: Scalars['JSONObject'];
};

export type UpdateStoreInput = {
  id: Scalars['String'];
  data: UpdateStoreDataInput;
};

export type UpdateStoreDataInput = {
  code?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  cartURL?: Maybe<Scalars['String']>;
  orderURL?: Maybe<Scalars['String']>;
  orderEmail?: Maybe<Scalars['String']>;
  supportEmail?: Maybe<Scalars['String']>;
  meta?: Maybe<Scalars['JSONObject']>;
};

export type CreateVoucherInput = {
  code: Scalars['String'];
  type: VoucherDiscountType;
  discount: Scalars['Float'];
  applicableTo?: Maybe<VoucherApplicableToInput>;
  rules: Array<VoucherRuleInput>;
  startsAt: Scalars['DateTime'];
  expiresAt?: Maybe<Scalars['DateTime']>;
  maxUses?: Maybe<Scalars['Float']>;
};

export type VoucherApplicableToInput = {
  method: VoucherApplicableToMethod;
  match: Scalars['JSONObject'];
};

export type VoucherRuleInput = {
  discount?: Maybe<Scalars['Float']>;
  match: Scalars['JSONObject'];
};

export type UpdateVoucherInput = {
  id: Scalars['String'];
  data: CreateVoucherInput;
};

export type ApplyOrRemoveVoucherInput = {
  id: Scalars['String'];
  code: Scalars['String'];
};

export type AddressFragment = { __typename?: 'Address' } & Pick<
  Address,
  'recipient' | 'line1' | 'line2' | 'city' | 'state' | 'postalCode' | 'country'
>;

export type AdjustmentFragment = { __typename?: 'Adjustment' } & Pick<
  Adjustment,
  'id' | 'type' | 'amount' | 'meta'
>;

export type CartFragment = { __typename?: 'Cart' } & Pick<
  Cart,
  | 'id'
  | 'createdAt'
  | 'updatedAt'
  | 'approvedAt'
  | 'approvedBy'
  | 'email'
  | 'uuid'
  | 'firstName'
  | 'lastName'
  | 'fullName'
  | 'phone'
  | 'meta'
  | 'notes'
  | 'status'
  | 'paid'
  | 'paidAt'
  | 'total'
  | 'adjustmentsTotal'
  | 'itemsTotal'
  | 'isApproved'
  | 'isEditable'
  | 'isPaid'
  | 'store'
> & {
    payments: Array<{ __typename?: 'Transaction' } & TransactionFragment>;
    refunds: Array<{ __typename?: 'Transaction' } & TransactionFragment>;
    shipments: Array<{ __typename?: 'Shipment' } & ShipmentFragment>;
    fulfillments: Array<{ __typename?: 'Fulfillment' } & FulfillmentFragment>;
    voucher?: Maybe<{ __typename?: 'Adjustment' } & AdjustmentFragment>;
    adjustments: Array<{ __typename?: 'Adjustment' } & AdjustmentFragment>;
    items: Array<{ __typename?: 'Item' } & ItemFragment>;
    billingAddress?: Maybe<{ __typename?: 'Address' } & AddressFragment>;
    shippingAddress?: Maybe<{ __typename?: 'Address' } & AddressFragment>;
  };

export type CartSimpleFragment = { __typename?: 'Cart' } & Pick<
  Cart,
  | 'id'
  | 'createdAt'
  | 'updatedAt'
  | 'approvedAt'
  | 'approvedBy'
  | 'email'
  | 'uuid'
  | 'firstName'
  | 'lastName'
  | 'fullName'
  | 'phone'
  | 'status'
  | 'paid'
  | 'paidAt'
  | 'notes'
  | 'total'
  | 'adjustmentsTotal'
  | 'itemsTotal'
  | 'isApproved'
  | 'isEditable'
  | 'isPaid'
  | 'meta'
  | 'store'
> & {
    customer?: Maybe<
      { __typename?: 'Customer' } & Pick<Customer, 'id' | 'email' | 'meta'>
    >;
    payments: Array<{ __typename?: 'Transaction' } & TransactionSimpleFragment>;
    shipments: Array<{ __typename?: 'Shipment' } & ShipmentFragment>;
    voucher?: Maybe<{ __typename?: 'Adjustment' } & AdjustmentFragment>;
    adjustments: Array<{ __typename?: 'Adjustment' } & AdjustmentFragment>;
    items: Array<{ __typename?: 'Item' } & ItemSimpleFragment>;
    shippingAddress?: Maybe<{ __typename?: 'Address' } & AddressFragment>;
  };

export type FulfillmentFragment = { __typename?: 'Fulfillment' } & Pick<
  Fulfillment,
  | 'id'
  | 'items'
  | 'source'
  | 'status'
  | 'meta'
  | 'notes'
  | 'createdAt'
  | 'updatedAt'
>;

export type ItemFragment = { __typename?: 'Item' } & Pick<
  Item,
  | 'id'
  | 'sku'
  | 'category'
  | 'unitPrice'
  | 'quantity'
  | 'total'
  | 'name'
  | 'meta'
  | 'metaUpdatedAt'
  | 'addedAt'
  | 'isPaid'
  | 'isRefunded'
> & {
    product?: Maybe<{ __typename?: 'Product' } & ProductFragment>;
    payment?: Maybe<{ __typename?: 'Transaction' } & TransactionFragment>;
    refund?: Maybe<{ __typename?: 'Transaction' } & TransactionFragment>;
  };

export type ItemSimpleFragment = { __typename?: 'Item' } & Pick<
  Item,
  | 'id'
  | 'sku'
  | 'category'
  | 'unitPrice'
  | 'quantity'
  | 'total'
  | 'name'
  | 'meta'
  | 'metaUpdatedAt'
  | 'addedAt'
  | 'isPaid'
  | 'isRefunded'
> & {
    product?: Maybe<{ __typename?: 'Product' } & ProductFragment>;
    payment?: Maybe<{ __typename?: 'Transaction' } & TransactionSimpleFragment>;
    refund?: Maybe<{ __typename?: 'Transaction' } & TransactionSimpleFragment>;
  };

export type ProductFragment = { __typename?: 'Product' } & Pick<
  Product,
  | 'id'
  | 'name'
  | 'sku'
  | 'type'
  | 'unitPrice'
  | 'category'
  | 'commodityCode'
  | 'upc'
  | 'meta'
  | 'requiresApproval'
  | 'createdAt'
  | 'updatedAt'
>;

export type ShipmentFragment = { __typename?: 'Shipment' } & Pick<
  Shipment,
  | 'id'
  | 'fulfillment'
  | 'source'
  | 'sourceId'
  | 'status'
  | 'carrier'
  | 'trackingNumber'
  | 'notes'
  | 'meta'
  | 'createdAt'
  | 'updatedAt'
>;

export type StoreFragment = { __typename?: 'Store' } & Pick<
  Store,
  | 'code'
  | 'name'
  | 'url'
  | 'cartURL'
  | 'orderURL'
  | 'orderEmail'
  | 'supportEmail'
  | 'meta'
>;

export type TransactionFragment = { __typename?: 'Transaction' } & Pick<
  Transaction,
  | 'id'
  | 'source'
  | 'sourceId'
  | 'sourceStatus'
  | 'type'
  | 'amount'
  | 'createdAt'
>;

export type TransactionSimpleFragment = { __typename?: 'Transaction' } & Pick<
  Transaction,
  'id' | 'source' | 'amount' | 'createdAt'
>;

export type UserFragment = { __typename?: 'User' } & Pick<
  User,
  'id' | 'createdAt' | 'updatedAt' | 'name' | 'email' | 'roles'
>;

export type VoucherFragment = { __typename?: 'Voucher' } & Pick<
  Voucher,
  | 'id'
  | 'createdAt'
  | 'updatedAt'
  | 'code'
  | 'type'
  | 'discount'
  | 'startsAt'
  | 'expiresAt'
  | 'useCount'
  | 'maxUses'
  | 'isUsable'
> & {
    applicableTo?: Maybe<
      { __typename?: 'VoucherApplicableTo' } & Pick<
        VoucherApplicableTo,
        'method' | 'match'
      >
    >;
    rules: Array<
      { __typename?: 'VoucherRule' } & Pick<VoucherRule, 'discount' | 'match'>
    >;
  };

export type CreateCartMutationVariables = Exact<{
  input?: Maybe<CreateCartInput>;
}>;

export type CreateCartMutation = { __typename?: 'Mutation' } & {
  createCart?: Maybe<{ __typename?: 'Cart' } & CartSimpleFragment>;
};

export type UpdateCartMutationVariables = Exact<{
  input: UpdateCartInput;
}>;

export type UpdateCartMutation = { __typename?: 'Mutation' } & {
  updateCart?: Maybe<{ __typename?: 'Cart' } & CartSimpleFragment>;
};

export type UpdateCartStatusMutationVariables = Exact<{
  input: UpdateCartStatusInput;
}>;

export type UpdateCartStatusMutation = { __typename?: 'Mutation' } & {
  updateCartStatus: { __typename?: 'Cart' } & CartSimpleFragment;
};

export type AddItemsToCartMutationVariables = Exact<{
  input: AddItemsToCartInput;
}>;

export type AddItemsToCartMutation = { __typename?: 'Mutation' } & {
  addItemsToCart?: Maybe<{ __typename?: 'Cart' } & CartSimpleFragment>;
};

export type RemoveItemsFromCartMutationVariables = Exact<{
  input: RemoveItemsFromCartInput;
}>;

export type RemoveItemsFromCartMutation = { __typename?: 'Mutation' } & {
  removeItemsFromCart?: Maybe<{ __typename?: 'Cart' } & CartSimpleFragment>;
};

export type UpdateCartItemMutationVariables = Exact<{
  input: UpdateCartItemInput;
}>;

export type UpdateCartItemMutation = { __typename?: 'Mutation' } & {
  updateCartItem?: Maybe<{ __typename?: 'Cart' } & CartSimpleFragment>;
};

export type AddAdjustmentToCartMutationVariables = Exact<{
  input: AddAdjustmentToCartInput;
}>;

export type AddAdjustmentToCartMutation = { __typename?: 'Mutation' } & {
  addAdjustmentToCart?: Maybe<{ __typename?: 'Cart' } & CartSimpleFragment>;
};

export type UpdateAdjustmentForCartMutationVariables = Exact<{
  input: UpdateAdjustmentForCartInput;
}>;

export type UpdateAdjustmentForCartMutation = { __typename?: 'Mutation' } & {
  updateAdjustmentForCart?: Maybe<{ __typename?: 'Cart' } & CartFragment>;
};

export type RemoveAdjustmentFromCartMutationVariables = Exact<{
  input: RemoveAdjustmentFromCartInput;
}>;

export type RemoveAdjustmentFromCartMutation = { __typename?: 'Mutation' } & {
  removeAdjustmentFromCart?: Maybe<{ __typename?: 'Cart' } & CartFragment>;
};

export type UpdateCartAndChargeMutationVariables = Exact<{
  updateCartInput: UpdateCartInput;
  chargeBraintreeInput: ChargeBraintreeInput;
}>;

export type UpdateCartAndChargeMutation = { __typename?: 'Mutation' } & {
  updateCart?: Maybe<{ __typename?: 'Cart' } & CartSimpleFragment>;
  chargeBraintree?: Maybe<{ __typename?: 'Cart' } & CartSimpleFragment>;
};

export type CreateBraintreeClientTokenMutationVariables = Exact<{
  input: CreateBraintreeClientTokenInput;
}>;

export type CreateBraintreeClientTokenMutation = { __typename?: 'Mutation' } & {
  createBraintreeClientToken?: Maybe<
    { __typename?: 'CreateBraintreeClientTokenResponseType' } & Pick<
      CreateBraintreeClientTokenResponseType,
      'clientToken'
    >
  >;
};

export type ChargeBraintreeMutationVariables = Exact<{
  input: ChargeBraintreeInput;
}>;

export type ChargeBraintreeMutation = { __typename?: 'Mutation' } & {
  chargeBraintree?: Maybe<{ __typename?: 'Cart' } & CartSimpleFragment>;
};

export type ApplyVoucherMutationVariables = Exact<{
  input: ApplyOrRemoveVoucherInput;
}>;

export type ApplyVoucherMutation = { __typename?: 'Mutation' } & {
  applyVoucher?: Maybe<{ __typename?: 'Cart' } & CartSimpleFragment>;
};

export type RemoveVoucherMutationVariables = Exact<{
  input: ApplyOrRemoveVoucherInput;
}>;

export type RemoveVoucherMutation = { __typename?: 'Mutation' } & {
  removeVoucher?: Maybe<{ __typename?: 'Cart' } & CartSimpleFragment>;
};

export type ApproveCartMutationVariables = Exact<{
  input: ApproveCartInput;
}>;

export type ApproveCartMutation = { __typename?: 'Mutation' } & {
  approveCart: { __typename?: 'Cart' } & CartSimpleFragment;
};

export type CartQueryVariables = Exact<{
  id: Scalars['String'];
}>;

export type CartQuery = { __typename?: 'Query' } & {
  cart?: Maybe<{ __typename?: 'Cart' } & CartFragment>;
};

export type CartFromFulfillmentQueryVariables = Exact<{
  id: Scalars['String'];
}>;

export type CartFromFulfillmentQuery = { __typename?: 'Query' } & {
  cartFromFulfillment?: Maybe<{ __typename?: 'Cart' } & CartFragment>;
};

export type ProductQueryVariables = Exact<{
  input: ProductQueryInput;
}>;

export type ProductQuery = { __typename?: 'Query' } & {
  product?: Maybe<{ __typename?: 'Product' } & ProductFragment>;
};

export type ProductsQueryVariables = Exact<{ [key: string]: never }>;

export type ProductsQuery = { __typename?: 'Query' } & {
  products: Array<{ __typename?: 'Product' } & ProductFragment>;
};

export const TransactionFragmentDoc = gql`
  fragment Transaction on Transaction {
    id
    source
    sourceId
    sourceStatus
    type
    amount
    createdAt
  }
`;
export const ShipmentFragmentDoc = gql`
  fragment Shipment on Shipment {
    id
    fulfillment
    source
    sourceId
    status
    carrier
    trackingNumber
    notes
    meta
    createdAt
    updatedAt
  }
`;
export const FulfillmentFragmentDoc = gql`
  fragment Fulfillment on Fulfillment {
    id
    items
    source
    status
    meta
    notes
    createdAt
    updatedAt
  }
`;
export const AdjustmentFragmentDoc = gql`
  fragment Adjustment on Adjustment {
    id
    type
    amount
    meta
  }
`;
export const ProductFragmentDoc = gql`
  fragment Product on Product {
    id
    name
    sku
    type
    unitPrice
    category
    commodityCode
    upc
    meta
    requiresApproval
    createdAt
    updatedAt
  }
`;
export const ItemFragmentDoc = gql`
  fragment Item on Item {
    id
    sku
    category
    unitPrice
    quantity
    total
    name
    meta
    metaUpdatedAt
    addedAt
    isPaid
    isRefunded
    product {
      ...Product
    }
    payment {
      ...Transaction
    }
    refund {
      ...Transaction
    }
  }
  ${ProductFragmentDoc}
  ${TransactionFragmentDoc}
`;
export const AddressFragmentDoc = gql`
  fragment Address on Address {
    recipient
    line1
    line2
    city
    state
    postalCode
    country
  }
`;
export const CartFragmentDoc = gql`
  fragment Cart on Cart {
    id
    createdAt
    updatedAt
    approvedAt
    approvedBy
    email
    uuid
    firstName
    lastName
    fullName
    phone
    meta
    notes
    status
    paid
    paidAt
    total
    adjustmentsTotal
    itemsTotal
    isApproved
    isEditable
    isPaid
    store
    payments {
      ...Transaction
    }
    refunds {
      ...Transaction
    }
    shipments {
      ...Shipment
    }
    fulfillments {
      ...Fulfillment
    }
    voucher {
      ...Adjustment
    }
    adjustments {
      ...Adjustment
    }
    items {
      ...Item
    }
    billingAddress {
      ...Address
    }
    shippingAddress {
      ...Address
    }
  }
  ${TransactionFragmentDoc}
  ${ShipmentFragmentDoc}
  ${FulfillmentFragmentDoc}
  ${AdjustmentFragmentDoc}
  ${ItemFragmentDoc}
  ${AddressFragmentDoc}
`;
export const TransactionSimpleFragmentDoc = gql`
  fragment TransactionSimple on Transaction {
    id
    source
    amount
    createdAt
  }
`;
export const ItemSimpleFragmentDoc = gql`
  fragment ItemSimple on Item {
    id
    sku
    category
    unitPrice
    quantity
    total
    name
    meta
    metaUpdatedAt
    addedAt
    isPaid
    isRefunded
    product {
      ...Product
    }
    payment {
      ...TransactionSimple
    }
    refund {
      ...TransactionSimple
    }
  }
  ${ProductFragmentDoc}
  ${TransactionSimpleFragmentDoc}
`;
export const CartSimpleFragmentDoc = gql`
  fragment CartSimple on Cart {
    id
    createdAt
    updatedAt
    approvedAt
    approvedBy
    email
    uuid
    firstName
    lastName
    fullName
    phone
    status
    paid
    paidAt
    notes
    total
    adjustmentsTotal
    itemsTotal
    isApproved
    isEditable
    isPaid
    meta
    store
    customer {
      id
      email
      meta
    }
    payments {
      ...TransactionSimple
    }
    shipments {
      ...Shipment
    }
    voucher {
      ...Adjustment
    }
    adjustments {
      ...Adjustment
    }
    items {
      ...ItemSimple
    }
    shippingAddress {
      ...Address
    }
  }
  ${TransactionSimpleFragmentDoc}
  ${ShipmentFragmentDoc}
  ${AdjustmentFragmentDoc}
  ${ItemSimpleFragmentDoc}
  ${AddressFragmentDoc}
`;
export const StoreFragmentDoc = gql`
  fragment Store on Store {
    code
    name
    url
    cartURL
    orderURL
    orderEmail
    supportEmail
    meta
  }
`;
export const UserFragmentDoc = gql`
  fragment User on User {
    id
    createdAt
    updatedAt
    name
    email
    roles
  }
`;
export const VoucherFragmentDoc = gql`
  fragment Voucher on Voucher {
    id
    createdAt
    updatedAt
    applicableTo {
      method
      match
    }
    rules {
      discount
      match
    }
    code
    type
    discount
    startsAt
    expiresAt
    useCount
    maxUses
    isUsable
  }
`;
export const CreateCartDocument = gql`
  mutation createCart($input: CreateCartInput) {
    createCart(input: $input) {
      ...CartSimple
    }
  }
  ${CartSimpleFragmentDoc}
`;
export type CreateCartMutationFn = Apollo.MutationFunction<
  CreateCartMutation,
  CreateCartMutationVariables
>;

/**
 * __useCreateCartMutation__
 *
 * To run a mutation, you first call `useCreateCartMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCartMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCartMutation, { data, loading, error }] = useCreateCartMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCartMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateCartMutation,
    CreateCartMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateCartMutation, CreateCartMutationVariables>(
    CreateCartDocument,
    options
  );
}
export type CreateCartMutationHookResult = ReturnType<
  typeof useCreateCartMutation
>;
export type CreateCartMutationResult = Apollo.MutationResult<CreateCartMutation>;
export type CreateCartMutationOptions = Apollo.BaseMutationOptions<
  CreateCartMutation,
  CreateCartMutationVariables
>;
export const UpdateCartDocument = gql`
  mutation updateCart($input: UpdateCartInput!) {
    updateCart(input: $input) {
      ...CartSimple
    }
  }
  ${CartSimpleFragmentDoc}
`;
export type UpdateCartMutationFn = Apollo.MutationFunction<
  UpdateCartMutation,
  UpdateCartMutationVariables
>;

/**
 * __useUpdateCartMutation__
 *
 * To run a mutation, you first call `useUpdateCartMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCartMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCartMutation, { data, loading, error }] = useUpdateCartMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCartMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateCartMutation,
    UpdateCartMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateCartMutation, UpdateCartMutationVariables>(
    UpdateCartDocument,
    options
  );
}
export type UpdateCartMutationHookResult = ReturnType<
  typeof useUpdateCartMutation
>;
export type UpdateCartMutationResult = Apollo.MutationResult<UpdateCartMutation>;
export type UpdateCartMutationOptions = Apollo.BaseMutationOptions<
  UpdateCartMutation,
  UpdateCartMutationVariables
>;
export const UpdateCartStatusDocument = gql`
  mutation updateCartStatus($input: UpdateCartStatusInput!) {
    updateCartStatus(input: $input) {
      ...CartSimple
    }
  }
  ${CartSimpleFragmentDoc}
`;
export type UpdateCartStatusMutationFn = Apollo.MutationFunction<
  UpdateCartStatusMutation,
  UpdateCartStatusMutationVariables
>;

/**
 * __useUpdateCartStatusMutation__
 *
 * To run a mutation, you first call `useUpdateCartStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCartStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCartStatusMutation, { data, loading, error }] = useUpdateCartStatusMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCartStatusMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateCartStatusMutation,
    UpdateCartStatusMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateCartStatusMutation,
    UpdateCartStatusMutationVariables
  >(UpdateCartStatusDocument, options);
}
export type UpdateCartStatusMutationHookResult = ReturnType<
  typeof useUpdateCartStatusMutation
>;
export type UpdateCartStatusMutationResult = Apollo.MutationResult<UpdateCartStatusMutation>;
export type UpdateCartStatusMutationOptions = Apollo.BaseMutationOptions<
  UpdateCartStatusMutation,
  UpdateCartStatusMutationVariables
>;
export const AddItemsToCartDocument = gql`
  mutation addItemsToCart($input: AddItemsToCartInput!) {
    addItemsToCart(input: $input) {
      ...CartSimple
    }
  }
  ${CartSimpleFragmentDoc}
`;
export type AddItemsToCartMutationFn = Apollo.MutationFunction<
  AddItemsToCartMutation,
  AddItemsToCartMutationVariables
>;

/**
 * __useAddItemsToCartMutation__
 *
 * To run a mutation, you first call `useAddItemsToCartMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddItemsToCartMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addItemsToCartMutation, { data, loading, error }] = useAddItemsToCartMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddItemsToCartMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddItemsToCartMutation,
    AddItemsToCartMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AddItemsToCartMutation,
    AddItemsToCartMutationVariables
  >(AddItemsToCartDocument, options);
}
export type AddItemsToCartMutationHookResult = ReturnType<
  typeof useAddItemsToCartMutation
>;
export type AddItemsToCartMutationResult = Apollo.MutationResult<AddItemsToCartMutation>;
export type AddItemsToCartMutationOptions = Apollo.BaseMutationOptions<
  AddItemsToCartMutation,
  AddItemsToCartMutationVariables
>;
export const RemoveItemsFromCartDocument = gql`
  mutation removeItemsFromCart($input: RemoveItemsFromCartInput!) {
    removeItemsFromCart(input: $input) {
      ...CartSimple
    }
  }
  ${CartSimpleFragmentDoc}
`;
export type RemoveItemsFromCartMutationFn = Apollo.MutationFunction<
  RemoveItemsFromCartMutation,
  RemoveItemsFromCartMutationVariables
>;

/**
 * __useRemoveItemsFromCartMutation__
 *
 * To run a mutation, you first call `useRemoveItemsFromCartMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveItemsFromCartMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeItemsFromCartMutation, { data, loading, error }] = useRemoveItemsFromCartMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveItemsFromCartMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveItemsFromCartMutation,
    RemoveItemsFromCartMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RemoveItemsFromCartMutation,
    RemoveItemsFromCartMutationVariables
  >(RemoveItemsFromCartDocument, options);
}
export type RemoveItemsFromCartMutationHookResult = ReturnType<
  typeof useRemoveItemsFromCartMutation
>;
export type RemoveItemsFromCartMutationResult = Apollo.MutationResult<RemoveItemsFromCartMutation>;
export type RemoveItemsFromCartMutationOptions = Apollo.BaseMutationOptions<
  RemoveItemsFromCartMutation,
  RemoveItemsFromCartMutationVariables
>;
export const UpdateCartItemDocument = gql`
  mutation updateCartItem($input: UpdateCartItemInput!) {
    updateCartItem(input: $input) {
      ...CartSimple
    }
  }
  ${CartSimpleFragmentDoc}
`;
export type UpdateCartItemMutationFn = Apollo.MutationFunction<
  UpdateCartItemMutation,
  UpdateCartItemMutationVariables
>;

/**
 * __useUpdateCartItemMutation__
 *
 * To run a mutation, you first call `useUpdateCartItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCartItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCartItemMutation, { data, loading, error }] = useUpdateCartItemMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCartItemMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateCartItemMutation,
    UpdateCartItemMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateCartItemMutation,
    UpdateCartItemMutationVariables
  >(UpdateCartItemDocument, options);
}
export type UpdateCartItemMutationHookResult = ReturnType<
  typeof useUpdateCartItemMutation
>;
export type UpdateCartItemMutationResult = Apollo.MutationResult<UpdateCartItemMutation>;
export type UpdateCartItemMutationOptions = Apollo.BaseMutationOptions<
  UpdateCartItemMutation,
  UpdateCartItemMutationVariables
>;
export const AddAdjustmentToCartDocument = gql`
  mutation addAdjustmentToCart($input: AddAdjustmentToCartInput!) {
    addAdjustmentToCart(input: $input) {
      ...CartSimple
    }
  }
  ${CartSimpleFragmentDoc}
`;
export type AddAdjustmentToCartMutationFn = Apollo.MutationFunction<
  AddAdjustmentToCartMutation,
  AddAdjustmentToCartMutationVariables
>;

/**
 * __useAddAdjustmentToCartMutation__
 *
 * To run a mutation, you first call `useAddAdjustmentToCartMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddAdjustmentToCartMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addAdjustmentToCartMutation, { data, loading, error }] = useAddAdjustmentToCartMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddAdjustmentToCartMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddAdjustmentToCartMutation,
    AddAdjustmentToCartMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AddAdjustmentToCartMutation,
    AddAdjustmentToCartMutationVariables
  >(AddAdjustmentToCartDocument, options);
}
export type AddAdjustmentToCartMutationHookResult = ReturnType<
  typeof useAddAdjustmentToCartMutation
>;
export type AddAdjustmentToCartMutationResult = Apollo.MutationResult<AddAdjustmentToCartMutation>;
export type AddAdjustmentToCartMutationOptions = Apollo.BaseMutationOptions<
  AddAdjustmentToCartMutation,
  AddAdjustmentToCartMutationVariables
>;
export const UpdateAdjustmentForCartDocument = gql`
  mutation updateAdjustmentForCart($input: UpdateAdjustmentForCartInput!) {
    updateAdjustmentForCart(input: $input) {
      ...Cart
    }
  }
  ${CartFragmentDoc}
`;
export type UpdateAdjustmentForCartMutationFn = Apollo.MutationFunction<
  UpdateAdjustmentForCartMutation,
  UpdateAdjustmentForCartMutationVariables
>;

/**
 * __useUpdateAdjustmentForCartMutation__
 *
 * To run a mutation, you first call `useUpdateAdjustmentForCartMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAdjustmentForCartMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAdjustmentForCartMutation, { data, loading, error }] = useUpdateAdjustmentForCartMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateAdjustmentForCartMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateAdjustmentForCartMutation,
    UpdateAdjustmentForCartMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateAdjustmentForCartMutation,
    UpdateAdjustmentForCartMutationVariables
  >(UpdateAdjustmentForCartDocument, options);
}
export type UpdateAdjustmentForCartMutationHookResult = ReturnType<
  typeof useUpdateAdjustmentForCartMutation
>;
export type UpdateAdjustmentForCartMutationResult = Apollo.MutationResult<UpdateAdjustmentForCartMutation>;
export type UpdateAdjustmentForCartMutationOptions = Apollo.BaseMutationOptions<
  UpdateAdjustmentForCartMutation,
  UpdateAdjustmentForCartMutationVariables
>;
export const RemoveAdjustmentFromCartDocument = gql`
  mutation removeAdjustmentFromCart($input: RemoveAdjustmentFromCartInput!) {
    removeAdjustmentFromCart(input: $input) {
      ...Cart
    }
  }
  ${CartFragmentDoc}
`;
export type RemoveAdjustmentFromCartMutationFn = Apollo.MutationFunction<
  RemoveAdjustmentFromCartMutation,
  RemoveAdjustmentFromCartMutationVariables
>;

/**
 * __useRemoveAdjustmentFromCartMutation__
 *
 * To run a mutation, you first call `useRemoveAdjustmentFromCartMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveAdjustmentFromCartMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeAdjustmentFromCartMutation, { data, loading, error }] = useRemoveAdjustmentFromCartMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveAdjustmentFromCartMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveAdjustmentFromCartMutation,
    RemoveAdjustmentFromCartMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RemoveAdjustmentFromCartMutation,
    RemoveAdjustmentFromCartMutationVariables
  >(RemoveAdjustmentFromCartDocument, options);
}
export type RemoveAdjustmentFromCartMutationHookResult = ReturnType<
  typeof useRemoveAdjustmentFromCartMutation
>;
export type RemoveAdjustmentFromCartMutationResult = Apollo.MutationResult<RemoveAdjustmentFromCartMutation>;
export type RemoveAdjustmentFromCartMutationOptions = Apollo.BaseMutationOptions<
  RemoveAdjustmentFromCartMutation,
  RemoveAdjustmentFromCartMutationVariables
>;
export const UpdateCartAndChargeDocument = gql`
  mutation updateCartAndCharge(
    $updateCartInput: UpdateCartInput!
    $chargeBraintreeInput: ChargeBraintreeInput!
  ) {
    updateCart(input: $updateCartInput) {
      ...CartSimple
    }
    chargeBraintree(input: $chargeBraintreeInput) {
      ...CartSimple
    }
  }
  ${CartSimpleFragmentDoc}
`;
export type UpdateCartAndChargeMutationFn = Apollo.MutationFunction<
  UpdateCartAndChargeMutation,
  UpdateCartAndChargeMutationVariables
>;

/**
 * __useUpdateCartAndChargeMutation__
 *
 * To run a mutation, you first call `useUpdateCartAndChargeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCartAndChargeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCartAndChargeMutation, { data, loading, error }] = useUpdateCartAndChargeMutation({
 *   variables: {
 *      updateCartInput: // value for 'updateCartInput'
 *      chargeBraintreeInput: // value for 'chargeBraintreeInput'
 *   },
 * });
 */
export function useUpdateCartAndChargeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateCartAndChargeMutation,
    UpdateCartAndChargeMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateCartAndChargeMutation,
    UpdateCartAndChargeMutationVariables
  >(UpdateCartAndChargeDocument, options);
}
export type UpdateCartAndChargeMutationHookResult = ReturnType<
  typeof useUpdateCartAndChargeMutation
>;
export type UpdateCartAndChargeMutationResult = Apollo.MutationResult<UpdateCartAndChargeMutation>;
export type UpdateCartAndChargeMutationOptions = Apollo.BaseMutationOptions<
  UpdateCartAndChargeMutation,
  UpdateCartAndChargeMutationVariables
>;
export const CreateBraintreeClientTokenDocument = gql`
  mutation createBraintreeClientToken(
    $input: CreateBraintreeClientTokenInput!
  ) {
    createBraintreeClientToken(input: $input) {
      clientToken
    }
  }
`;
export type CreateBraintreeClientTokenMutationFn = Apollo.MutationFunction<
  CreateBraintreeClientTokenMutation,
  CreateBraintreeClientTokenMutationVariables
>;

/**
 * __useCreateBraintreeClientTokenMutation__
 *
 * To run a mutation, you first call `useCreateBraintreeClientTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBraintreeClientTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBraintreeClientTokenMutation, { data, loading, error }] = useCreateBraintreeClientTokenMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateBraintreeClientTokenMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateBraintreeClientTokenMutation,
    CreateBraintreeClientTokenMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateBraintreeClientTokenMutation,
    CreateBraintreeClientTokenMutationVariables
  >(CreateBraintreeClientTokenDocument, options);
}
export type CreateBraintreeClientTokenMutationHookResult = ReturnType<
  typeof useCreateBraintreeClientTokenMutation
>;
export type CreateBraintreeClientTokenMutationResult = Apollo.MutationResult<CreateBraintreeClientTokenMutation>;
export type CreateBraintreeClientTokenMutationOptions = Apollo.BaseMutationOptions<
  CreateBraintreeClientTokenMutation,
  CreateBraintreeClientTokenMutationVariables
>;
export const ChargeBraintreeDocument = gql`
  mutation chargeBraintree($input: ChargeBraintreeInput!) {
    chargeBraintree(input: $input) {
      ...CartSimple
    }
  }
  ${CartSimpleFragmentDoc}
`;
export type ChargeBraintreeMutationFn = Apollo.MutationFunction<
  ChargeBraintreeMutation,
  ChargeBraintreeMutationVariables
>;

/**
 * __useChargeBraintreeMutation__
 *
 * To run a mutation, you first call `useChargeBraintreeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChargeBraintreeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [chargeBraintreeMutation, { data, loading, error }] = useChargeBraintreeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useChargeBraintreeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ChargeBraintreeMutation,
    ChargeBraintreeMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ChargeBraintreeMutation,
    ChargeBraintreeMutationVariables
  >(ChargeBraintreeDocument, options);
}
export type ChargeBraintreeMutationHookResult = ReturnType<
  typeof useChargeBraintreeMutation
>;
export type ChargeBraintreeMutationResult = Apollo.MutationResult<ChargeBraintreeMutation>;
export type ChargeBraintreeMutationOptions = Apollo.BaseMutationOptions<
  ChargeBraintreeMutation,
  ChargeBraintreeMutationVariables
>;
export const ApplyVoucherDocument = gql`
  mutation applyVoucher($input: ApplyOrRemoveVoucherInput!) {
    applyVoucher(input: $input) {
      ...CartSimple
    }
  }
  ${CartSimpleFragmentDoc}
`;
export type ApplyVoucherMutationFn = Apollo.MutationFunction<
  ApplyVoucherMutation,
  ApplyVoucherMutationVariables
>;

/**
 * __useApplyVoucherMutation__
 *
 * To run a mutation, you first call `useApplyVoucherMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApplyVoucherMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [applyVoucherMutation, { data, loading, error }] = useApplyVoucherMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useApplyVoucherMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ApplyVoucherMutation,
    ApplyVoucherMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ApplyVoucherMutation,
    ApplyVoucherMutationVariables
  >(ApplyVoucherDocument, options);
}
export type ApplyVoucherMutationHookResult = ReturnType<
  typeof useApplyVoucherMutation
>;
export type ApplyVoucherMutationResult = Apollo.MutationResult<ApplyVoucherMutation>;
export type ApplyVoucherMutationOptions = Apollo.BaseMutationOptions<
  ApplyVoucherMutation,
  ApplyVoucherMutationVariables
>;
export const RemoveVoucherDocument = gql`
  mutation removeVoucher($input: ApplyOrRemoveVoucherInput!) {
    removeVoucher(input: $input) {
      ...CartSimple
    }
  }
  ${CartSimpleFragmentDoc}
`;
export type RemoveVoucherMutationFn = Apollo.MutationFunction<
  RemoveVoucherMutation,
  RemoveVoucherMutationVariables
>;

/**
 * __useRemoveVoucherMutation__
 *
 * To run a mutation, you first call `useRemoveVoucherMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveVoucherMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeVoucherMutation, { data, loading, error }] = useRemoveVoucherMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveVoucherMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveVoucherMutation,
    RemoveVoucherMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RemoveVoucherMutation,
    RemoveVoucherMutationVariables
  >(RemoveVoucherDocument, options);
}
export type RemoveVoucherMutationHookResult = ReturnType<
  typeof useRemoveVoucherMutation
>;
export type RemoveVoucherMutationResult = Apollo.MutationResult<RemoveVoucherMutation>;
export type RemoveVoucherMutationOptions = Apollo.BaseMutationOptions<
  RemoveVoucherMutation,
  RemoveVoucherMutationVariables
>;
export const ApproveCartDocument = gql`
  mutation approveCart($input: ApproveCartInput!) {
    approveCart(input: $input) {
      ...CartSimple
    }
  }
  ${CartSimpleFragmentDoc}
`;
export type ApproveCartMutationFn = Apollo.MutationFunction<
  ApproveCartMutation,
  ApproveCartMutationVariables
>;

/**
 * __useApproveCartMutation__
 *
 * To run a mutation, you first call `useApproveCartMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApproveCartMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [approveCartMutation, { data, loading, error }] = useApproveCartMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useApproveCartMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ApproveCartMutation,
    ApproveCartMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ApproveCartMutation, ApproveCartMutationVariables>(
    ApproveCartDocument,
    options
  );
}
export type ApproveCartMutationHookResult = ReturnType<
  typeof useApproveCartMutation
>;
export type ApproveCartMutationResult = Apollo.MutationResult<ApproveCartMutation>;
export type ApproveCartMutationOptions = Apollo.BaseMutationOptions<
  ApproveCartMutation,
  ApproveCartMutationVariables
>;
export const CartDocument = gql`
  query cart($id: String!) {
    cart(id: $id) {
      ...Cart
    }
  }
  ${CartFragmentDoc}
`;

/**
 * __useCartQuery__
 *
 * To run a query within a React component, call `useCartQuery` and pass it any options that fit your needs.
 * When your component renders, `useCartQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCartQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCartQuery(
  baseOptions: Apollo.QueryHookOptions<CartQuery, CartQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CartQuery, CartQueryVariables>(CartDocument, options);
}
export function useCartLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<CartQuery, CartQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CartQuery, CartQueryVariables>(
    CartDocument,
    options
  );
}
export type CartQueryHookResult = ReturnType<typeof useCartQuery>;
export type CartLazyQueryHookResult = ReturnType<typeof useCartLazyQuery>;
export type CartQueryResult = Apollo.QueryResult<CartQuery, CartQueryVariables>;
export const CartFromFulfillmentDocument = gql`
  query cartFromFulfillment($id: String!) {
    cartFromFulfillment(id: $id) {
      ...Cart
    }
  }
  ${CartFragmentDoc}
`;

/**
 * __useCartFromFulfillmentQuery__
 *
 * To run a query within a React component, call `useCartFromFulfillmentQuery` and pass it any options that fit your needs.
 * When your component renders, `useCartFromFulfillmentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCartFromFulfillmentQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCartFromFulfillmentQuery(
  baseOptions: Apollo.QueryHookOptions<
    CartFromFulfillmentQuery,
    CartFromFulfillmentQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    CartFromFulfillmentQuery,
    CartFromFulfillmentQueryVariables
  >(CartFromFulfillmentDocument, options);
}
export function useCartFromFulfillmentLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CartFromFulfillmentQuery,
    CartFromFulfillmentQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CartFromFulfillmentQuery,
    CartFromFulfillmentQueryVariables
  >(CartFromFulfillmentDocument, options);
}
export type CartFromFulfillmentQueryHookResult = ReturnType<
  typeof useCartFromFulfillmentQuery
>;
export type CartFromFulfillmentLazyQueryHookResult = ReturnType<
  typeof useCartFromFulfillmentLazyQuery
>;
export type CartFromFulfillmentQueryResult = Apollo.QueryResult<
  CartFromFulfillmentQuery,
  CartFromFulfillmentQueryVariables
>;
export const ProductDocument = gql`
  query product($input: ProductQueryInput!) {
    product(input: $input) {
      ...Product
    }
  }
  ${ProductFragmentDoc}
`;

/**
 * __useProductQuery__
 *
 * To run a query within a React component, call `useProductQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useProductQuery(
  baseOptions: Apollo.QueryHookOptions<ProductQuery, ProductQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ProductQuery, ProductQueryVariables>(
    ProductDocument,
    options
  );
}
export function useProductLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ProductQuery, ProductQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ProductQuery, ProductQueryVariables>(
    ProductDocument,
    options
  );
}
export type ProductQueryHookResult = ReturnType<typeof useProductQuery>;
export type ProductLazyQueryHookResult = ReturnType<typeof useProductLazyQuery>;
export type ProductQueryResult = Apollo.QueryResult<
  ProductQuery,
  ProductQueryVariables
>;
export const ProductsDocument = gql`
  query products {
    products {
      ...Product
    }
  }
  ${ProductFragmentDoc}
`;

/**
 * __useProductsQuery__
 *
 * To run a query within a React component, call `useProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductsQuery({
 *   variables: {
 *   },
 * });
 */
export function useProductsQuery(
  baseOptions?: Apollo.QueryHookOptions<ProductsQuery, ProductsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ProductsQuery, ProductsQueryVariables>(
    ProductsDocument,
    options
  );
}
export function useProductsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ProductsQuery,
    ProductsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ProductsQuery, ProductsQueryVariables>(
    ProductsDocument,
    options
  );
}
export type ProductsQueryHookResult = ReturnType<typeof useProductsQuery>;
export type ProductsLazyQueryHookResult = ReturnType<
  typeof useProductsLazyQuery
>;
export type ProductsQueryResult = Apollo.QueryResult<
  ProductsQuery,
  ProductsQueryVariables
>;
